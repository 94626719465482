<template>
  <div class="deletion-popup__wrapper" ref="popup_wrapper" @click="handleClose">
    <div class="deletion-popup" :style="{ width: width }">
      <div class="deletion-popup__close">
        <slot name="close"></slot>
      </div>
      <div class="deletion-popup__block block">
        <div class="block__image">
          <slot name="image"></slot>
        </div>
        <div class="block__header">
          <slot name="header"></slot>
        </div>
        <div class="block__content">
          <slot name="text"></slot>
        </div>
        <div class="block__footer button">
          <slot name="buttonCancel"></slot>
          <slot name="buttonDelete"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalBasic",
  props: {
    width: {
      type: String,
      default: '',
    }
  },
  methods: {
    handleClose(event) {
      if (event.target === this.$refs['popup_wrapper']) {
        this.$emit('closePopup');
      }
    }
  }
}
</script>

<style scoped lang="scss">
.deletion-popup__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;

  .deletion-popup {
    position: relative;
    padding: 18px;
    z-index: 33;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #DDDCE8;
    max-width: 90%;
    width: 600px;
    margin: 20px 0;

    @media (max-width: 768px) {
      width: 90%;
      padding: 15px;
    }

    @media (max-width: 480px) {
      width: 95%;
      padding: 10px;
      border-radius: 5px;
    }

    &__close {
      float: right;
    }

    .block {
      padding: 15px;

      @media (max-width: 480px) {
        padding: 10px;
      }

      &__image {
        text-align: center;
      }

      &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        text-align: center;

        @media (max-width: 480px) {
          padding: 10px;
        }
      }

      &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 480px) {
          margin-bottom: 15px;
        }
      }

      &__footer {
        display: flex;
        justify-content: center;
        gap: 20px;

        @media (max-width: 480px) {
          gap: 10px;
          flex-wrap: wrap;
        }
      }
    }
  }
}
</style>
